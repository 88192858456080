import React from 'react';
import { WindowLocation } from '@reach/router';
import styled, { createGlobalStyle } from 'styled-components';

import '@fortawesome/fontawesome-free/css/all.css';

import NavigationBar from './navigation-bar';
import FooterBar from './footer-bar';
import '../../index.css';
import { THEME } from '../../constants/theme';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url('/font/roboto/Roboto-Regular.ttf');
  }

  @font-face {
    font-family: 'Roboto Black';
    src: url('/font/roboto/Roboto-Black.ttf');
  }

  @font-face {
    font-family: 'Roboto Light';
    src: url('/font/roboto/Roboto-Light.ttf');
  }

  html, body {
    padding: 0;
    margin: 0;
    line-height: 1.7;
    font-family: "Roboto Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  html, body, body>div, body>div>div {
    height: 100%;
  }
  
  @media (max-width: 952px) {
    font-size: 16px;
  }

  @media (max-width: 858px) {
    font-size: 20px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface ImageContainer {
  readonly height: string;
}

const ImageContainer = styled.div<ImageContainer>`
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  overflow: hidden;

  .gatsby-image-wrapper {
    background-color: ${THEME.middle};
    height: ${({ height }) => height};

    img {
      object-position: bottom !important;
      bottom: 0;
      top: initial !important;
    }
  }
`;

interface LayoutProps {
  readonly location: WindowLocation;
  readonly footer?: boolean;
  readonly image?: IGatsbyImageData;
}

const Layout: React.FC<LayoutProps> = ({ location, children, image, footer = true }) => (
  <>
    <GlobalStyle />
    <Root>
      {image && (
        <ImageContainer height="40vh">
          <GatsbyImage image={image} alt={'Header'} />
        </ImageContainer>
      )}
      <NavigationBar location={location} />
      {children}
      {footer && <FooterBar />}
    </Root>
  </>
);

export default Layout;
