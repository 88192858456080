import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// @ts-ignore: Cannot find module
import logoLight from '../../assets/logo_light.png';
// @ts-ignore: Cannot find module
import logoDark from '../../assets/logo_dark.png';

const FlexLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;

  @media (min-width: 858px) {
    flex: 1;
  }
`;

const Logo = styled.img`
  height: 1.5rem;
  margin: 0 1rem;
`;

interface SiteLogoProps {
  darkMode: boolean;
}

const SiteLogo: React.FC<SiteLogoProps> = ({ darkMode }) => (
  <FlexLink to={'/'}>
    <Logo src={darkMode ? logoLight : logoDark} alt={`Rafael Kullas Photography`} />
  </FlexLink>
);

export default SiteLogo;
