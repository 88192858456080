import styled from 'styled-components';
import { THEME } from '../../constants/theme';

export const FixedNav = styled.nav`
  background: linear-gradient(${THEME.gray}70, transparent);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: baseline;

  @media (max-width: 858px) {
    justify-content: space-between;
    align-items: center;
  }
`;
