import React from 'react';
import { Link } from 'gatsby';
import { WindowLocation } from '@reach/router';
import styled from 'styled-components';

import { THEME } from '../../constants/theme';

// @ts-ignore: Cannot find name '__PATH_PREFIX__'
const rootPath = `${__PATH_PREFIX__}/`;

const isActive = (pathname: string, to: string) => (to === rootPath ? to === pathname : pathname.startsWith(to));

const ListItem = styled.li`
  list-style: none;
  display: inline-block;
  line-height: 40px;
  margin: 0 5px;

  @media (max-width: 858px) {
    display: block;
    line-height: 30px;
  }
`;

interface SelectableLinkProps {
  active?: boolean;
  darkMode: boolean;
}

const SelectableLink = styled(Link)<SelectableLinkProps>`
  color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  padding: 2px 13px;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1rem;
  width: 100%;
  display: block;

  :hover,
  :active {
    color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    transition: 0.5s;
    text-decoration: none;
  }

  @media (min-width: 858px) {
    border-bottom: 0.1rem solid transparent;
    :hover,
    :active {
      border-bottom-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    }
  }

  @media (max-width: 858px) {
    border-left: 0.1rem solid transparent;
    :hover,
    :active {
      border-left-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    }
  }
`;

interface NavigationItemProps {
  location?: WindowLocation;
  to: string;
  label: string;
  darkMode: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ location, to, label, darkMode }) => (
  <ListItem>
    <SelectableLink darkMode={darkMode} active={location && isActive(location.pathname, to)} to={to}>
      {label}
    </SelectableLink>
  </ListItem>
);

export default NavigationItem;
