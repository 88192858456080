import NavigationItem from './navigation-item';
import React from 'react';
import styled from 'styled-components';

import SiteLogo from './site-logo';
import { THEME } from '../../constants/theme';

const Root = styled.div`
  margin: 4rem 0 2rem 0;
  flex-direction: column !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  color: ${THEME.text};

  a,
  a:visited,
  a:hover {
    line-height: 40px;
    color: ${THEME.text};
  }
`;

const Nav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

const FooterBar: React.FC<{}> = () => (
  <Root>
    <SiteLogo darkMode={false} />
    <a href="mailto:rafael@kullas.photography">
      <i className="far fa-envelope" /> rafael@kullas.photography
    </a>
    <Nav>
      <NavigationItem label="Impressum" to="/impressum" darkMode={false} />
      <NavigationItem label="Datenschutz" to="/privacy" darkMode={false} />
    </Nav>
  </Root>
);

export default FooterBar;
