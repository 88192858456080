import React from 'react';
import styled from 'styled-components';

import { THEME } from '../../constants/theme';

interface NavIconProps {
  darkMode: boolean;
}

const NavIcon = styled.a<NavIconProps>`
  color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  font-size: 1.3rem;
  padding: 0.3rem;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  border: 0.1rem solid transparent;
  text-decoration: none;

  :hover,
  :active,
  :visited {
    color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    transition: 0.5s;
    text-decoration: none;
    border-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  }
`;

const NavItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1rem;
`;

interface NavigationIconProps {
  iconClass: string;
  to: string;
  title: string;
  darkMode: boolean;
}

const NavigationIcon: React.FC<NavigationIconProps> = ({ iconClass, to, title, darkMode }) => (
  <NavItem>
    <NavIcon
      className={`fab ${iconClass}`}
      href={to}
      title={title}
      rel="noopener"
      darkMode={darkMode}
      target="_blank"
    />
  </NavItem>
);

export default NavigationIcon;
