import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

type Meta = {
  name?: string;
  content: string;
  property?: string;
};

interface HeadProps {
  readonly title: string;
  readonly description?: string;
  readonly imageSrc?: string;
  readonly lang?: string;
  readonly keywords?: string[];
}

export const Head: React.FC<HeadProps> = ({ title, description, lang, imageSrc, keywords }) => (
  <StaticQuery<GatsbyTypes.SiteQueryQuery>
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
            author {
              name
            }
          }
        }
      }
    `}
    render={(data: GatsbyTypes.SiteQueryQuery): React.ReactElement | null => {
      const metaDescription = description || data.site?.siteMetadata?.description || '';
      lang = lang || 'de';
      keywords = keywords || [];
      const meta: Meta[] = [
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site?.siteMetadata?.author?.name || '',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ];
      if (keywords.length > 0) {
        meta.push({
          name: `keywords`,
          content: keywords.join(`, `),
        });
      }
      if (imageSrc) {
        meta.push({
          name: `twitter:image`,
          content: imageSrc,
        });
      }
      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${data.site?.siteMetadata?.title}`}
          meta={meta}
        />
      );
    }}
  />
);
