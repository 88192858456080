import styled from 'styled-components';
import { THEME } from '../../constants/theme';

export const DefaultNav = styled.nav`
  color: ${THEME.text};
  background-color: ${THEME.middle};
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: baseline;

  @media (min-width: 858px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  @media (max-width: 858px) {
    justify-content: space-between;
    align-items: center;
  }
`;
