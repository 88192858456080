import React from 'react';
import styled from 'styled-components';
import { THEME } from '../../constants/theme';

interface DarkProps {
  darkMode: boolean;
}

const ActionListItem = styled.li<DarkProps>`
  list-style: none;
  display: inline-block;
  line-height: 40px;
  margin: 0 5px;
  cursor: pointer;
  display: none;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  border-bottom-style: solid;
  border-bottom-width: 1px;

  i {
    font-size: 1.5rem;
    font-weight: bold;
  }

  @media (max-width: 858px) {
    display: block;
    line-height: 30px;
  }
`;

const SelectableLink = styled.div<DarkProps>`
  color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  font-weight: normal;
  padding: 2px 13px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 1rem;
  width: 100%;
  display: block;

  :hover {
    color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    transition: 0.5s;
    text-decoration: none;
  }

  @media (max-width: 858px) {
    border-left: 0.1rem solid transparent;
    :hover,
    :active {
      border-left-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    }
  }
`;

interface MobileActionButtonProps {
  label?: string;
  onClick: () => void;
  darkMode: boolean;
}

const MobileActionButton: React.FC<MobileActionButtonProps> = ({ onClick, label, children, darkMode }) => (
  <ActionListItem darkMode={darkMode} onClick={onClick}>
    <SelectableLink darkMode={darkMode}>{label || children}</SelectableLink>
  </ActionListItem>
);

export default MobileActionButton;
