import React, { useState } from 'react';
import { WindowLocation } from '@reach/router';

import NavigationItem from './navigation-item';
import NavigationIcon from './navigation-icon';
import styled from 'styled-components';
import NavigationDropdown from './navigation-dropdown';
import { FixedNav } from './fixed-nav';
import { DefaultNav } from './default-nav';
import SiteLogo from './site-logo';
import { THEME } from '../../constants/theme';
import MobileActionButton from './mobile-action-button';

// @ts-ignore: Cannot find name '__PATH_PREFIX__'
const rootPath = `${__PATH_PREFIX__}/`;

interface NavListProps {
  darkMode: boolean;
  open: boolean;
}

const NavList = styled.ul<NavListProps>`
  display: flex;
  flex-direction: row;
  margin: 0;
  background: ${({ darkMode }) => (darkMode ? 'transparent' : THEME.middle)};

  @media (max-width: 858px) {
    background: ${({ darkMode }) => (darkMode ? THEME.gray : THEME.middle)};
    position: fixed;
    top: 0;
    left: ${({ open }) => (open ? '0' : '-16rem')};
    max-width: 15rem;
    width: 15rem;
    height: 100vh;
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
    transition: all 0.5s;
    z-index: 1030;
    padding: 1rem;
    box-shadow: 0px 16px 16px 0px ${THEME.dark};
    box-sizing: border-box;
  }
`;

interface CheckBtnProps {
  darkMode: boolean;
}

const CheckBtn = styled.label<CheckBtnProps>`
  font-size: 1.5rem;
  color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  float: right;
  line-height: 40px;
  cursor: pointer;
  display: none;

  @media (max-width: 858px) {
    display: block;
  }
`;

enum SubNavigation {
  PORTFOLIO,
  MORE,
}

interface NavigationBarProps {
  location: WindowLocation;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ location }) => {
  const landingPage = location.pathname == rootPath;
  const Root = landingPage ? FixedNav : DefaultNav;
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const [selectedSubnav, setSelectedSubnav] = useState<SubNavigation | undefined>();
  const selectSubnav = (subnav: SubNavigation) => () =>
    setSelectedSubnav(selectedSubnav === subnav ? undefined : subnav);
  return (
    <Root>
      <CheckBtn darkMode={landingPage} onClick={() => setNavBarOpen(!navBarOpen)}>
        <i className="fas fa-bars" />
      </CheckBtn>
      <SiteLogo darkMode={landingPage} />
      <NavList darkMode={landingPage} open={navBarOpen}>
        <MobileActionButton
          onClick={() => {
            setNavBarOpen(!navBarOpen);
            setSelectedSubnav(undefined);
          }}
          darkMode={landingPage}
        >
          <i className="fas fa-angle-left" />
        </MobileActionButton>
        <NavigationItem label="Home" to="/" location={location} darkMode={landingPage} />
        <NavigationDropdown
          label="Portfolio"
          darkMode={landingPage}
          open={selectedSubnav === SubNavigation.PORTFOLIO}
          onClick={selectSubnav(SubNavigation.PORTFOLIO)}
        >
          <NavigationItem to="/portfolio/landscape" label="Landscape" location={location} darkMode={landingPage} />
          <NavigationItem to="/portfolio/cityscape" label="Cityscape" location={location} darkMode={landingPage} />
        </NavigationDropdown>
        <NavigationItem label="Blog" to="/blog" location={location} darkMode={landingPage} />
        <NavigationDropdown
          label="More"
          darkMode={landingPage}
          open={selectedSubnav === SubNavigation.MORE}
          onClick={selectSubnav(SubNavigation.MORE)}
        >
          <NavigationItem label="Über Mich" to="/about" location={location} darkMode={landingPage} />
          <NavigationItem label="Impressum" to="/impressum" location={location} darkMode={landingPage} />
          <NavigationItem label="Datenschutz" to="/privacy" location={location} darkMode={landingPage} />
        </NavigationDropdown>
        <NavigationIcon
          darkMode={landingPage}
          iconClass="fa-instagram"
          title="Instagram"
          to="https://www.instagram.com/rafael.kullas.photography/"
        />
      </NavList>
      <div></div>
    </Root>
  );
};

export default NavigationBar;
