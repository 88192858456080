import React from 'react';
import styled from 'styled-components';

import { THEME } from '../../constants/theme';
import { DefaultNav } from './default-nav';
import { FixedNav } from './fixed-nav';

const Dropdown = styled.div`
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

interface DropDownContentProps {
  darkMode: boolean;
  open: boolean;
}

const DropDownContent = styled.ul<DropDownContentProps>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  background-color: ${THEME.light};
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px ${THEME.dark};
  z-index: 1;
  list-style: none;
  flex-direction: column;
  padding: 0.3rem 0;
  font-size: 1rem;
  top: 95%;
  left: 0;

  li {
    overflow: hidden;
  }

  :hover {
    display: flex;
  }

  ${FixedNav} & {
    background-color: ${THEME.gray};
  }

  ${DefaultNav} & {
    background-color: ${THEME.middle};
  }

  @media (max-width: 858px) {
    top: 0;
    left: 100%;
  }
`;

interface DropDownBtnProps {
  darkMode: boolean;
}

const DropDownBtn = styled.label<DropDownBtnProps>`
  cursor: pointer;
  display: block;
  border: none;
  outline: none;
  color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
  padding: 0 13px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 40px;

  :hover ~ ${DropDownContent} {
    display: flex;
  }

  @media (max-width: 858px) {
    border-left: 0.1rem solid transparent;
    line-height: 30px;
    :hover,
    :active {
      border-left-color: ${({ darkMode }) => (darkMode ? THEME.contrastText : THEME.text)};
    }
  }
`;

const Icon = styled.i`
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;

  :before {
    content: '\f107';
  }
  @media (max-width: 858px) {
    :before {
      content: '\f105';
    }
  }
`;

interface NavigationDropdownProps {
  label: string;
  onClick: () => void;
  open: boolean;
  darkMode: boolean;
}

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({ label, onClick, open, children, darkMode }) => (
  <Dropdown>
    <DropDownBtn darkMode={darkMode} onClick={onClick}>
      {label} <Icon className="fa" />
    </DropDownBtn>
    <DropDownContent open={open} darkMode={darkMode}>
      {children}
    </DropDownContent>
  </Dropdown>
);

export default NavigationDropdown;
